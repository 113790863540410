export default [
  {
    header: 'Chức năng',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Tổng quan ',
        route: 'dashboard-ecommerce',
        icon: 'LayersIcon',
      },
      {
        title: 'Khách hàng',
        route: 'dashboard-analytics',
        icon: 'UsersIcon',
      },
      {
        title: 'Đại lý',
        route: 'dashboard-analytics',
        icon: 'UsersIcon',
      },
    ],
  },
]
